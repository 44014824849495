import React, { useEffect } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import pt from "prop-types";
import SuccessPage from "../../components/global/SuccessPage";

const ResetPasswordSuccess = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "green-bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);

  useEffect(() => {
    if (!location.state || !location.state.email) {
      navigate("/reset-password");
      return undefined;
    }
  });

  return (
    <SuccessPage
      pageTitle="Password Reset Link Sent"
      image={data.bgImage}
      message="Password reset link sent"
      subMessage="We've sent you an email with a link to reset your password"
    />
  );
};

ResetPasswordSuccess.propTypes = {
  location: pt.shape({
    state: pt.shape({
      email: pt.string,
    }),
  }).isRequired,
};

export default ResetPasswordSuccess;
