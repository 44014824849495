import PropTypes from "prop-types";
import React from "react";
import theme from "../../theme";
import { Box, Container, Typography } from "@mui/material";
import AppButton from "./elements/AppButton";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { boxShadowStyle } from "../../lib/styleConstants";

const styles = {
  centeredContent: {
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    display: "flex",
  },
  icon: {
    marginBottom: "40px",
    width: "70px",
    height: "auto",
  },
  contentWrapper: {
    marginTop: "10vh",
    marginBottom: "10vh",
    width: "80vw",
    display: "flex",
    padding: "30px 0",
    flexDirection: "column",
    justifyContent: "center",

    "& row": {
      width: "100%",
    },
  },
  sub: {
    fontWeight: "200",
    color: theme.palette.grey.warmGrey,
  },
  subMessage: {
    marginBottom: "50px",
    fontWeight: 100,
  },
  button: {
    width: "100%",
    borderRadius: 0,
  },
};

const SuccessPage = ({ message, subMessage, buttonLink }) => {
  return (
    <Container sx={{ ...styles.contentWrapper, ...boxShadowStyle }}>
      <Box sx={styles.centeredContent}>
        <DoneAllIcon color="secondary" sx={styles.icon} />

        <Typography variant="h4">{message}</Typography>
        <br />

        <Typography variant="h6" style={styles.subMessage}>
          {subMessage}
        </Typography>

        {buttonLink && (
          <Box sx={{ paddingTop: "30px", width: "100%" }}>
            <AppButton
              link={buttonLink}
              look="black"
              addtionalStyle={styles.button}
              label="Sign In"
            />
          </Box>
        )}
      </Box>
    </Container>
  );
};

SuccessPage.propTypes = {
  message: PropTypes.string.isRequired,
  subMessage: PropTypes.string.isRequired,
  buttonLink: PropTypes.string,
};
SuccessPage.defaultProps = {
  buttonLink: undefined,
};

export default SuccessPage;
